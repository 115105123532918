import moment from "moment";
import {get, map, filter, toString} from "xe-utils";
import {HUI_QI_ZHENG_CE_LEVEL} from "./config";

/**
 * 惠企政策状态 ，未开始、进行中、已结束
 *
 * @returns {{name: string, status: boolean}}
 * @param shen_bao_start_time  开始时间
 * @param shen_bao_end_time 结束时间
 */
export function huiQiZhengCeStatus(shen_bao_start_time, shen_bao_end_time) {
    if (moment(shen_bao_end_time).diff(moment(), 'second')<= 0) {
        return {
            name: '已结束',
            status: false,
            text:'#919090',
            bg:'#E3E3E3'
        }
    }
    const tian = moment(shen_bao_start_time).diff(moment(), 'second')



    if(tian <= 90*86400 && tian > 0){
        return  {
            name: '即将开始',
            status: false,
            bg:'#ebf1ff',
            text:'#2a70ce',
        }
    }

    if (tian > 90*86400) {

        return {
            name: '未开始',
            status: false,
            text:'#919090',
            bg:'#E3E3E3'
        }
    }



    return {
        name: '进行中',
        status: true,
        bg:'#daf2da',
        text:'#008000',
    }
}

/**
 * 拼接地址名称
 * 格式
 * [
 *     {
 *         "shen_id": 30,
 *         "shi_id": 388,
 *         "qu_id": null,
 *         "policy_zheng_ce_lie_biao_id": 6,
 *         "join_shen_id": {
 *             "id": 30,
 *             "name": "浙江"
 *         },
 *         "join_shi_id": {
 *             "id": 388,
 *             "name": "绍兴"
 *         }
 *     },
 *     {
 *         "shen_id": 30,
 *         "shi_id": 392,
 *         "qu_id": null,
 *         "policy_zheng_ce_lie_biao_id": 6,
 *         "join_shen_id": {
 *             "id": 30,
 *             "name": "浙江"
 *         },
 *         "join_shi_id": {
 *             "id": 392,
 *             "name": "衢州"
 *         }
 *     },
 *     {
 *         "shen_id": 30,
 *         "shi_id": 391,
 *         "qu_id": null,
 *         "policy_zheng_ce_lie_biao_id": 6,
 *         "join_shen_id": {
 *             "id": 30,
 *             "name": "浙江"
 *         },
 *         "join_shi_id": {
 *             "id": 391,
 *             "name": "舟山"
 *         }
 *     }
 * ]
 */
export const pingJieDiZhi = function (level, item) {
    if (toString(level) === toString(HUI_QI_ZHENG_CE_LEVEL.guo_jia.id)) {
        return ['全省']
    }
    item = map(item, n => {
        let val = [
            get(n, 'join_shen_id.name', ''),
            get(n, 'join_shi_id.name', ''),
            get(n, 'join_qu_id.name', ''),
        ]
        return filter(val, n2 => n2).join('/')
    })
    return item
}

