<script>
export default {
  name: 'YzTag',
  props: {
    type: {
      type: String, //danger primary success warning default plain
      default() {
        return 'default'
      }
    },
    backgroundColor: String,
    textColor: String,
  }
}
</script>

<template>
  <span class="biao-qian" :class="`yz-${type}`" :style="{'background':backgroundColor,'color':textColor}">
    <slot>标签</slot>
  </span>
</template>

<style scoped lang="less">
.biao-qian {
  background: #EBF1FC;
  padding: 2px 5px;
  font-size: 14px;
  color: white;
}

.yz-danger {
  background: #ee0a24a8;
}

.yz-primary {
  background: #4A4096;
}

.yz-success {
  background: #07c160;
}

.yz-warning {
  background: #ff976a;
}

.yz-default {
  background: #969799;
}


</style>
