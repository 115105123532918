<script>
import NavHeader from "@/components/top/NavHeader.vue";
import http from "@/api/http.js";
import {useRoute, useRouter} from "vue-router";
import din_shi from "@/api/din_shi.js";
import {ref, computed} from "vue";
import {each, find, get, map, toNumber, isEmpty, toString} from 'xe-utils'
import moment from "moment"
import {huiQiZhengCeStatus, pingJieDiZhi} from "@/package/gongJu";
import download from "@icon-park/vue-next/lib/icons/Download";
import {getFileType} from "@/api/file";
import FenXiangTiShi from "@/components/btn/fenXiangTiShi.vue";
import {setReturnUrl} from "@/api/cookie";
import qiYeWeiXi from "@/api/QiYeWeiXi";
import {getKeHuTel, getZaiXianKeFu} from "@/api/dataList/publicApi";
import YzCollapse from "@/package/YzCollapse/index.vue";
import YzTag from "@/package/YzTag/index.vue";

export default {
  name: 'policyInfo',
  computed: {
    download() {
      return download
    }
  },
  methods: {pingJieDiZhi},
  components: {YzTag, YzCollapse, FenXiangTiShi, NavHeader},
  setup() {
    const route = useRoute()
    const router = useRouter()
    const info = ref({})
    const yuanWenId = ref(get(route, 'params.yuanWenId', ''))

    http.get('/gzhphp/policy/get_policy_info', {params: {id: route.params.id}}).then(({code, msg, data}) => {
      if (code !== 1) {
        din_shi.error(msg)
        return
      }
      data.join_policy_zheng_ce_info_jie_du = map(data.join_policy_zheng_ce_info_jie_du, n => {
        n.zheDieZhuangTai = n.is_zhe_die === '是' ? false : true  //没开启折叠，默认展开，开启折叠，默认关闭
        return n
      })
      data.join_policy_zheng_ce_info_yuan_wen = map(data.join_policy_zheng_ce_info_yuan_wen, n => {
        n.zheDieZhuangTai = n.is_zhe_die === '是' ? false : true  //没开启折叠，默认展开，开启折叠，默认关闭
        return n
      })
      console.log('yuanWenId.value', yuanWenId.value)
      if (isEmpty(yuanWenId.value)) {

        yuanWenId.value = get(data, 'join_policy_zheng_ce_info_yuan_wen.0.id', '')
      }

      info.value = data

      qiYeWeiXi.fenXiang({
        title: get(info.value, 'name'),
        desc: get(info.value, 'jian_jie'),
        link: location.href,
        imgUrl: ''
      });

    })

    const yueDuHtml = computed(() => {
      let selectVal = find(info.value.join_policy_zheng_ce_info_yuan_wen, n => {
        console.log('333', toString(n.id), toString(yuanWenId.value))
        return toString(n.id) === toString(yuanWenId.value)
      })
      return selectVal
    })

    function isBetween() {

      return huiQiZhengCeStatus(get(info.value, 'shen_bao_start_time'), get(info.value, 'shen_bao_end_time'))
    }

    const pageType = isEmpty(route.params.pageType) ? 'jieDu' : route.params.pageType

    function zhanKai(offset) {
      each(get(info.value, 'join_policy_zheng_ce_info_jie_du', []), (n, i) => {
        if (i === offset) return //点击的那个跳过
        if (n.is_zhe_die === '是') {
          info.value.join_policy_zheng_ce_info_jie_du[i].zheDieZhuangTai = false
        }
      })
      info.value.join_policy_zheng_ce_info_jie_du[offset].zheDieZhuangTai = !info.value.join_policy_zheng_ce_info_jie_du[offset].zheDieZhuangTai
    }


    //下载pdf
    function downloadPdf() {
      let url = [
        get(info.value, 'pdf_url.0.url'),
        `?attname=${info.value.name}.`,
        getFileType(get(info.value, 'pdf_url.0.name'))
      ].join('')
      location.href = encodeURI(url)
    }

    function shouCang() {
      http.post("/gzhphp/lxr_policy/policy_shou_cang_change_status", {id: route.params.id}).then(({code, data}) => {
        if (code === 1) {
          info.value.join_shou_cang = data
        }
        if (get(data, 'status') === 'login') {
          setReturnUrl(route.fullPath)
          router.push({name: 'MobileLogin'})
        }
      })
    }

    function openChat() {
      getZaiXianKeFu().then(({code, data}) => {
        if (code === 1) location.href = data.content
      })
    }

    const tel = ref('')
    //获取电话号码
    getKeHuTel().then(({code, data}) => {
      tel.value = get(data, 'content')
    })

    const getJoinPolicyZhengCeInfoJieDu = computed(() => {
      return map(get(info.value, 'join_policy_zheng_ce_info_jie_du', []), (n, i) => {
        return {
          id: i + 1,
          hiddenZheDie: n.is_zhe_die === '是',
          title: n.name,
          html: n.content
        }
      })
    })

    return {
      getJoinPolicyZhengCeInfoJieDu,
      tel,
      openChat,
      shouCang,
      downloadPdf,
      yueDuHtml,
      zhanKai,
      pageType,
      toNumber,
      isBetween,
      moment,
      info,
      get,
      isEmpty
    }
  }
}
</script>

<template>
  <div class="tal-flex tal-flex-col tal-h-full">
    <div>
      <nav-header :title="get(info,'name')">
        <template #right>
          <fen-xiang-ti-shi/>
        </template>
      </nav-header>
    </div>
    <div class="tal-flex-1 tal-overflow-auto">
      <div class="tou-name  tal-relative" v-if="!isEmpty(info)">
        <div class="bei-jing">
        </div>
        <div class="tal-absolute tal-bottom-0 tal-left-0 tal-p-6 tal-w-full">
          <div
              class="tal-bg-white tal-shadow-md tal-p-4 tal-flex tal-flex-col tal-gap-4 tal-pt-6 tal-pb-6 tal-rounded-2xl">
            <div class="tal-text-3xl">
              <b>{{ get(info, 'name') }}</b>
            </div>
            <div class="tal-flex tal-gap-3 " style="flex-wrap: wrap;">
              <yz-tag :background-color="isBetween().bg" :text-color="isBetween().text">{{ isBetween().name }}</yz-tag>

              <yz-tag background-color="#EDECF3" text-color="#4A4096">
                {{ get(info, 'join_level.name') }}
              </yz-tag>
              <yz-tag
                  v-for="(v2,i2) in pingJieDiZhi(info.level,info.join_area)"
                  background-color="#EDECF3" text-color="#4A4096"
                  :key="i2"
              >
                {{ v2 }}
              </yz-tag>
              <yz-tag
                  background-color="#FFE5E7"
                  text-color="#FF617A"
                  v-if="info.jiang_jing !== ''"
              >
                有奖励
              </yz-tag>
              <yz-tag
                  background-color="#E3E3E3"
                  text-color="#919090"
                  v-else
              >
                无奖励
              </yz-tag>
            </div>
            <div class="tal-text-2xl">
              申报时间：<span class="tal-text-gray-500">{{ moment(info.shen_bao_start_time).format("yyyy-MM-DD") }} 至
              {{ moment(info.shen_bao_end_time).format("yyyy-MM-DD") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tal-mt-8" v-if="!isEmpty(info)">
        <div class="tal-flex tal-gap-40 tal-justify-center tal-text-3xl ">
          <span
              class="tal-text-gray-500"
              @click="$router.replace({name:'policyInfo',params:{id:info.id,pageType:'jieDu'}})"
              :class="{'tal-text-zhu-se':pageType === 'jieDu'}"
          >解读</span>
          <span
              class="tal-text-gray-500"
              @click="$router.replace({name:'policyInfo',params:{id:info.id,pageType:'yuanWen'}})"
              :class="{'tal-text-zhu-se':pageType === 'yuanWen'}"
          >
            原文
          </span>
        </div>
        <div class="tal-ml-3 tal-mr-3 tal-bg-white  tal-mt-6">
          <div class="" v-if="pageType === 'jieDu'">
            <yz-collapse top-background="#f5f5f5" :data-list="getJoinPolicyZhengCeInfoJieDu"/>
          </div>
          <div v-else>
            <div class="tal-p-2">
              <van-cell-group>
                <van-cell
                    v-for="(vo,i) in get(info,'join_policy_zheng_ce_info_yuan_wen',[])"
                    :key="i"
                    is-link
                    @click="$router.replace({name:'policyInfo',params:{id:info.id,pageType:'yuanWen',yuanWenId:vo.id}})"
                >
                  <template #title>
                    <span class="tal-text-2xl">{{ vo.name }}</span>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
            <div class="tal-bg-gray-100 tal-h-4" style="background:#F7F8FA;"></div>
            <div class="tal-mt-3 tal-p-2" v-html="get(yueDuHtml,'content')"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="tal-pt-2">
      <div class="lol-yin-ying tal-bg-white tal-p-2 tal-pt-3 tal-pb-3 tal-flex tal-gap-4 tal-text-sm">
        <div
            class="tal-flex tal-flex-col tal-items-center tal-justify-center tal-w-24"
        >
          <div @click="downloadPdf">
            <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10 4H30L40 14V42C40 43.1046 39.1046 44 38 44H10C8.89543 44 8 43.1046 8 42V6C8 4.89543 8.89543 4 10 4Z"
                  fill="none" stroke="#333" stroke-width="3" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18H30V25.9917L18.0083 26L18 18Z" stroke="#333"
                    stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18 18V34" stroke="#333" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </div>
          <div @click="downloadPdf">PDF下载</div>
        </div>

        <div class="tal-flex tal-flex-col tal-items-center tal-justify-center tal-w-24">
          <div @click="shouCang">
             <span v-if="info.join_shou_cang">
              <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M23.9986 5L17.8856 17.4776L4 19.4911L14.0589 29.3251L11.6544 43L23.9986 36.4192L36.3454 43L33.9586 29.3251L44 19.4911L30.1913 17.4776L23.9986 5Z"
                    fill="red" stroke="red" stroke-width="3" stroke-linejoin="round"/></svg>
            </span>
            <span v-else>
              <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M23.9986 5L17.8856 17.4776L4 19.4911L14.0589 29.3251L11.6544 43L23.9986 36.4192L36.3454 43L33.9586 29.3251L44 19.4911L30.1913 17.4776L23.9986 5Z"
                  fill="none" stroke="#333" stroke-width="3" stroke-linejoin="round"/>
            </svg>
            </span>

          </div>
          <div @click="shouCang">收藏</div>
        </div>
        <div class="tal-flex tal-flex-1">
          <a
              :href="`tel:${tel}`"
              class="tal-bg-blue-400 tal-rounded-l-full tal-w-1/2  tal-flex tal-items-center tal-justify-center tal-text-white tal-text-2xl"
          >
            电话咨询
          </a>
          <div
              @click="openChat"
              class="tal-bg-zhu-se tal-rounded-r-full tal-w-1/2 tal-flex tal-items-center tal-justify-center tal-text-white tal-text-2xl"
          >
            在线咨询
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.tou-name {
  position: relative;
  height: 180px;

  .bei-jing {
    height: 100px;
    background-image: url("http://yunzuofile.sxsgky.com/hui_qu_zheng_ce/hui_qi_zheng_ce_xiang_qing.jpg");
  }

}

.lol-yin-ying {
  background: #FFFFFF;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.21);
}

</style>
