export const HUI_QI_ZHENG_CE_LEVEL = {
    guo_jia: {
        name: '国家级别',
        id: 1081,
        level: 0,
    },
    shen: {
        name: '省级',
        id: 1080,
        level: 1,
    },
    shi: {
        name: '市级',
        id: 1078,
        level: 2,
    },
    qu: {
        name: '区域级',
        id: 1079,
        level: 3,
    }
}




